import { formatForApiUsage } from '../Pawshake/Core/Date';
import { CoreApi } from './CoreApi';

class SitterApi extends CoreApi {
    getPayoutInformationRequirements = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, `/profile/sitter-listing/payout-information-requirements`);
    };

    getBookableRates = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, `/profile/sitter-listing/bookable-rates`);
    };

    specifyBookableRates = (
        currency,
        offersHomeDogBoarding,
        homeDogBoardingBaseRateAmount,
        homeDogBoardingAdditionalRateAmount,
        offersDoggyDayCare,
        doggyDayCareBaseRateAmount,
        doggyDayCareAdditionalRateAmount,
        offersOneHomeVisit,
        oneHomeVisitBaseRateAmount,
        oneHomeVisitAdditionalRateAmount,
        offersTwoHomeVisits,
        twoHomeVisitsBaseRateAmount,
        twoHomeVisitsAdditionalRateAmount,
        offersDogWalking,
        dogWalkingBaseRateAmount,
        dogWalkingAdditionalRateAmount,
        offersHouseSitting,
        houseSittingBaseRateAmount,
        houseSittingAdditionalRateAmount,
        jwtToken
    ) => {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.BookableRates.WriteModel.SpecifyBookableRates', {
            currency,
            offersHomeDogBoarding,
            homeDogBoardingBaseRateAmount,
            homeDogBoardingAdditionalRateAmount,
            offersDoggyDayCare,
            doggyDayCareBaseRateAmount,
            doggyDayCareAdditionalRateAmount,
            offersOneHomeVisit,
            oneHomeVisitBaseRateAmount,
            oneHomeVisitAdditionalRateAmount,
            offersTwoHomeVisits,
            twoHomeVisitsBaseRateAmount,
            twoHomeVisitsAdditionalRateAmount,
            offersDogWalking,
            dogWalkingBaseRateAmount,
            dogWalkingAdditionalRateAmount,
            offersHouseSitting,
            houseSittingBaseRateAmount,
            houseSittingAdditionalRateAmount,
        });
    };

    getAdditionalServices = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, `/profile/sitter-listing/additional-services`);
    };

    specifyAdditionalServices = (sitterAdditionalServices, jwtToken) => {
        return this.sendCommand(
            jwtToken,
            'Pawshake.Sitter.AdditionalServices.WriteModel.SpecifySitterAdditionalServices',
            {
                sitterAdditionalServices,
            }
        );
    };

    getCancellationPolicy = (jwtToken) => {
        return this.performAuthenticatedGetRequest(
            jwtToken,
            `/profile/sitter-listing/cancellation-policy?withInfo=true`
        );
    };

    specifyCancellationPolicy = (cancellationPolicy, jwtToken) => {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.CancellationPolicy.WriteModel.SpecifyCancellationPolicy', {
            cancellationPolicy,
        });
    };

    getHome = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, `/profile/sitter-listing/home`);
    };

    specifySitterHome = (propertyType, outdoorArea, kids, hasFullyFencedBackyard, jwtToken) => {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.Home.WriteModel.SpecifySitterHome', {
            propertyType,
            outdoorArea,
            kids,
            hasFullyFencedBackyard,
        });
    };

    getWalkingAreas = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/profile/sitter-listing/walking-areas');
    };

    specifyWalkingAreas = (hasNearbyOffLeashArea, walkingAreaTypes, jwtToken) => {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.WalkingAreas.WriteModel.SpecifyWalkingAreas', {
            hasNearbyOffLeashArea,
            walkingAreaTypes,
        });
    };

    getPetPreferences = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/profile/sitter-listing/pet-preferences');
    };

    specifyPetPreferences = (dogAges, dogSizes, otherPetTypes, jwtToken) => {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.PetPreferences.WriteModel.SpecifyPetPreferences', {
            dogAges,
            dogSizes,
            otherPetTypes,
        });
    };

    getHeadLine = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/profile/sitter-listing/head-line');
    };

    specifyHeadLine = (headLine, jwtToken) => {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.HeadLine.WriteModel.SpecifyHeadLine', {
            headLine,
        });
    };

    getInformation = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/profile/sitter-listing/information');
    };

    specifyInformation(
        useLegacyFields,
        profileDescription,
        aboutYouInformation,
        requestedInformationFromPetOwner,
        serviceRelatedInformation,
        jwtToken
    ) {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.Information.WriteModel.SpecifySitterInformation', {
            useLegacyFields,
            profileDescription,
            aboutYouInformation,
            requestedInformationFromPetOwner,
            serviceRelatedInformation,
        });
    }

    getExperience = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/profile/sitter-listing/experience');
    };

    specifyExperience = (yearsOfExperience, skills, skillsDescription, jwtToken) => {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.Experience.WriteModel.SpecifySitterExperience', {
            yearsOfExperience,
            skills,
            skillsDescription,
        });
    };

    getProfileGallery = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/profile/sitter-listing/profile-gallery');
    };

    addImageToGallery = (base64EncodedImage, jwtToken) => {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.ProfileGallery.WriteModel.AddImageToGallery', {
            base64EncodedImage,
        });
    };

    deleteImageFromGallery = (imageId, jwtToken) => {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.ProfileGallery.WriteModel.DeleteImageFromGallery', {
            imageId,
        });
    };

    reorderGallery = (images, jwtToken) => {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.ProfileGallery.WriteModel.ReorderGallery', {
            galleryImages: images,
        });
    };

    getSpokenLanguages = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/profile/sitter-listing/spoken-languages');
    };

    specifySpokenLanguages = (spokenLanguages, jwtToken) => {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.SpokenLanguages.WriteModel.SpecifySpokenLanguages', {
            spokenLanguages,
        });
    };

    createSitterSubmission = (googlePlaceId, sessionToken, jwtToken) => {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.Application.WriteModel.CreateSitterSubmission', {
            googlePlaceId,
            sessionToken,
        });
    };

    sendSitterSubmissionForApproval = (jwtToken) => {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.Application.WriteModel.SendSitterSubmissionForApproval', {});
    };

    getSubmissionStatus = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/profile/sitter-listing/submission-status');
    };

    getSubmissionStatusCta = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/profile/sitter-listing/submission-status-cta');
    };

    getPetOwnerRebookInformation = (jwtToken, petOwnerUserId, bookingId) => {
        return this.performAuthenticatedGetRequest(
            jwtToken,
            `/owner/${petOwnerUserId}/rebook-information/based-upon-booking/${bookingId}`
        );
    };

    requestBookingAsSitter = (jwtToken, bookingRequest) => {
        return this.sendCommand(
            jwtToken,
            'Pawshake.Booking.SitterInitiatedBookings.WriteModel.RequestBookingAsPetSitter',
            {
                petOwnerUserId: bookingRequest.petOwnerUserId,
                message: bookingRequest.message,
                bookableService: bookingRequest.bookableService,
                petIds: bookingRequest.petIds,
                dates: bookingRequest.dates.map((date) => formatForApiUsage(date)),
                vas: bookingRequest.vas,
            }
        );
    };
}

export default new SitterApi();
