import i18n from '../i18n';
import cogoToast from 'cogo-toast';

export const showSavingToast = (text = undefined) => {
    const { hide } = cogoToast.loading(typeof text === 'undefined' ? i18n.i18n.t('toasts.saving') : text, {
        position: 'top-right',
        hideAfter: 0,
    });
    return hide;
};

export const showSuccessToast = (text = undefined) => {
    cogoToast.success(typeof text === 'undefined' ? i18n.i18n.t('toasts.saved') : text, {
        position: 'top-right',
    });
};

export const showFailedToast = (error, action) => {
    cogoToast.error(error, {
        position: 'top-right',
        hideAfter: 10,
        onClick: () => {
            if (action) {
                action();
            }
        },
    });
};
